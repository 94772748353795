
import store from "@/store";
import { Component, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import AuthModule from "@/store/modules/auth.module";
import { authenticate } from "@/services/api/authenticate.service";
import { userService } from "@/services/api/user.service";
import SearchBarModule from "@/store/modules/searchBar.module";
import { ProfileMenuFields } from "@/models/utils/profile-menu-fields.interface";
import { UserSignUp } from "@/models/entities/user-signup.interface";
import { VMenuDivider } from "@zelando/vuelando/dist/types/models/utils/v-menu-item.interface";
import { TutorialDialogItem } from "@zelando/vuelando";
import { AppBarNotice } from "@/models/entities/app-bar-notice.interface";

const authModule = getModule(AuthModule, store);
const searchBarModule = getModule(SearchBarModule, store);

@Component
export default class Layout extends Vue {
  private settingsDialog = false;
  private currentSearch = "";
  private componentKey = "";
  private timeout = null;
  path = require("@/assets/CBILogoRed.png");
  private beneficiaries = "";
  private leavePageDialog = false;
  private leavePageClick = "";
  private logoutDialog = false;
  private skip = true;
  private user: UserSignUp;
  private tutorialDialog = false;
  currDate = new Date().toFormat("yyyy");
  appBarNotice: AppBarNotice = {
    barMessage: "",
    tooltipMessage: "",
    showUntil: new Date(),
  };
  private userMenuItems: (ProfileMenuFields | VMenuDivider)[] = [
    {
      fieldName: this.$tc("account.Home"),
      icon: "home",
      key: "home",
    },
    {
      fieldName: this.$tc("account.Overview"),
      icon: "chart",
      key: "overview",
    },
    {
      fieldName: this.$tc("account.PersonalData"),
      icon: "user",
      key: "personalData",
    },
    {
      fieldName: this.$tc("account.Password"),
      icon: "key",
      key: "modifyPassword",
    },
    "divider",
    {
      fieldName: this.$tc("account.Payment"),
      icon: "box",
      key: "payments",
    },
    {
      fieldName: this.$tc("account.OrderingList"),
      icon: "clipboard",
      key: "orderingSubjectList",
    },
    "divider",
  ];
  private tutorialDialogItems: TutorialDialogItem[] = [
    {
      active: true,
      type: "section-parent",
      label: this.$tc("tutorialDialog.InsertOrderingSubject"),
      icon: "plus",
      iframeUrl:
        "https://scribehow.com/embed/CBIfast_Aggiunta_ordinante__CzcOLjxHQzWy_IVnJtu24w",
    },
    {
      active: false,
      type: "section-parent",
      label: this.$tc("tutorialDialog.ExcelModel"),
      icon: "xls",
      iframeUrl: "",
    },
    {
      active: false,
      type: "section-parent",
      label: this.$tc("tutorialDialog.InsertTransfers"),
      icon: "upload",
      iframeUrl:
        "https://scribehow.com/embed/CBIfast_come_importare_un_file_Excel__qvNNrJ0rTK22O9X4dqeSaw",
    },
    {
      active: false,
      type: "section-parent",
      label: this.$tc("tutorialDialog.CheckAndExport"),
      icon: "download",
      iframeUrl:
        "https://scribehow.com/embed/CBIfast_controllo_ed_esportazione__SH0SbuU-T9i8irjlkQ_Bjg",
    },
    {
      active: false,
      type: "section-parent",
      label: this.$tc("tutorialDialog.PlansAndPayments"),
      icon: "wallet",
      iframeUrl:
        "https://scribehow.com/embed/CBIfast_cambio_piano_a_pagamento__3A4KaiayScaQ02CbMttqNg",
    },
    {
      active: false,
      type: "section-parent",
      label: this.$tc("tutorialDialog.ManageAccount"),
      icon: "wrench",
      iframeUrl:
        "https://scribehow.com/embed/CBIfast_pannello_di_controllo___7kBmh5bQcu5AUL2BYKOwA",
    },
  ];
  chatBotDialog = false;
  private get roleDescription(): string {
    switch (authModule.profileRoleID) {
      case 1:
        return this.$tc("general.Administrator");
      case 2:
        return this.$tc("general.Free");
      case 3:
        return this.$tc("general.Base");
      case 4:
        return this.$tc("general.Standard");
      case 5:
        return this.$tc("general.Pro");
      case 6:
        return this.$tc("general.Unlimited");
      default:
        return "";
    }
  }

  get username(): string {
    return authModule.userName;
  }

  get email(): string {
    return authModule.userEmail;
  }

  get visibleSrcBar(): boolean {
    return searchBarModule.getVisible;
  }

  get currentPage(): string {
    return this.$route.params.context ?? "home";
  }

  get appBarNoticeVisible(): boolean {
    if (this.appBarNotice?.showUntil && this.appBarNotice?.barMessage) {
      const showUntilDate = new Date(this.appBarNotice?.showUntil);
      return !showUntilDate.isBefore(new Date());
    }
    return false;
  }

  @Watch("currentSearch")
  private currentSearchUpdated(): void {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => {
      searchBarModule.searchTable(
        this.currentSearch != null ? this.currentSearch : ""
      );
    }, 200);
  }

  private async mounted() {
    this.componentKey = this.$route.params.context;
    // add elements case administration.
    if (authModule.profileRoleID === 1) {
      this.userMenuItems.push({
        fieldName: this.$tc("account.Administration"),
        icon: "box",
        key: "administration",
      });
      this.userMenuItems.push("divider");
    }
    const locale = this.$i18n.locale;
    if (locale) {
      const res = await userService.GetAppBarNotice(locale);
      if (res?.data) {
        this.appBarNotice = res.data;
      }
    }
    const resTutorial = await userService.GetShouldShowTutorial();
    if (resTutorial?.data) {
      this.tutorialDialog = resTutorial.data;
    }
  }

  private changePage(): void {
    if (this.leavePageClick != this.currentPage) {
      this.leavePageDialog = false;
      this.$router.push({
        name: "account",
        params: {
          context: this.leavePageClick,
        },
      });
      searchBarModule.setVisibleAction(false);
    }
  }

  private logoutFunct(): void {
    this.leavePageDialog = false;
    this.$router.push({
      name: "Logout",
    });
  }

  private goToAccount(key: string): void {
    this.leavePageClick = key;
    if (searchBarModule.getVisible) {
      this.leavePageDialog = true;
    } else {
      this.changePage();
    }
  }

  private async logout(): Promise<void> {
    if (searchBarModule.getVisible && this.skip) {
      this.logoutDialog = true;
      this.skip = false;
    } else {
      this.skip = true;
      await authenticate.Logout();
      this.$router.push({
        name: "Logout",
      });
    }
  }

  private goHome() {
    if (this.$route.path != "/home") this.$router.push({ name: "home" });
    else location.reload();
  }

  private onLinkClick(): void {
    window.open("https://www.zelando.it/", "_blank");
  }

  private openTutorialDialog() {
    this.tutorialDialog = true;
  }

  private onChangeTutorialDialog(item: TutorialDialogItem, idx: number): void {
    this.tutorialDialogItems[idx].active = true;
  }

  private byWhatsapp() {
    window.open(
      "https://api.whatsapp.com/send/?phone=%2B390341282327&text=&type=phone_number&app_absent=0"
    );
  }

  private byEmail() {
    window.open("mailto: supporto@zelando.it?subject=");
  }
  private byPhone() {
    window.open("tel: 0341 282327");
  }
  chatBotDialogOpen(): void {
    this.chatBotDialog = true;
  }
  chatBotDialogClose(): void {
    this.chatBotDialog = false;
  }
}
